/* ---------------------------- React Router Dom ---------------------------- */
import { useLocation, useNavigate } from "react-router-dom";

/* ---------------------------------- Icons --------------------------------- */
import prev from "../assets/img/Icons/prev-btn.svg";
import { useContext, useEffect, useRef } from "react";
import { MainContext } from "../utils/MainContext";

const ButtonPrev = ({ text, link, image, className }) => {
  /* --------------------------------- Context -------------------------------- */
  const { setPrevButtonRef } = useContext(MainContext);

  /* ---------------------------- React Router Dom ---------------------------- */

  const location = useLocation();
  const navigate = useNavigate();

  const btnRef = useRef();

  useEffect(() => {
    if (btnRef) {
      setPrevButtonRef(btnRef);
    }
  }, [setPrevButtonRef]);

  return (
    <button
      ref={btnRef}
      className={`btn ${className}`}
      onClick={() => {
        if (location.pathname === "check-up") {
          return true;
        } else {
          navigate(link);
        }
      }}
    >
      {image && <img src={prev} alt="prev" className="prev" />}
      {text}
    </button>
  );
};

export default ButtonPrev;
