import { Link } from "react-router-dom";
import logo from "../assets/img/huge-logo.svg";
import unHide from "../assets/img/Icons/unhide.svg";
import hide from "../assets/img/Icons/hide.svg";
const Login = () => {
  return (
    <main>
      <section className="form-page">
        <div className="container">
          <div className="row">
            <div className="left-side">
              <div className="logo-box">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            <form className="right-side">
              <div className="info-group">
                <h1 className="caption">Login</h1>
                <p className="sub-title">
                  The customer is very happy, it will be followed customer
                  service. The most important disease
                </p>
              </div>

              <div className="form-group">
                <label htmlFor="Full name">Email</label>
                <input type="text" />
              </div>

              <div className="form-group">
                <div className="top">
                  <label htmlFor="Full name">Password</label>
                  <button className="icon">
                    <img src={hide} alt="unhide" />
                  </button>
                </div>
                <input type="text" />
              </div>
              <Link to="/forgot-password" className="forget-password">Fogote password</Link>

              <button className="submit">Login</button>
              <p className="login-adviser">
                Don’t have an account? <Link to="/sign-up">Sing up</Link>
              </p>
              <h2 className="alert-title">Other sing up accounts</h2>
              <h3 className="alert-sub-title">
                If you already have an account on these platforms, you don't
                need to register.
              </h3>
              <div className="related-website">
                <Link href="#">Vaccine App</Link>
                <Link href="#">Videodoctor</Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
