/* ---------------------------------- React --------------------------------- */
import React, { useCallback, useContext, useEffect, useState } from "react";

/* -------------------------------- Conponent ------------------------------- */
import BodyInfo from "./BodyInfo";

/* ----------------------------- Local Database ----------------------------- */
import questionsDb from "../../db/questionsDb";

/* --------------------------------- Context -------------------------------- */
import { MainContext } from "../../utils/MainContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SpecificInfo = () => {
  /* --------------------------------- Context -------------------------------- */
  const {
    checkupPage,
    setCheckupPage,
    prevButtonRef,
    nextButtonRef,
    bodyInfo,
    gender,
    token,
  } = useContext(MainContext);

  /* ------------------------------- Local State ------------------------------ */
  const [data, setData] = useState([]);
  const [staticData, setStaticData] = useState([
    {
      id: 1,
      type: "age",
      defaultValue: "",
    },
    {
      id: 2,
      type: "weight",
      defaultValue: "",
    },
    {
      id: 3,
      type: "height",
      defaultValue: "",
    },
  ]);
  const [contextData, setContextData] = useState(false);

  // Lnaguage
  const { i18n } = useTranslation();

  /* ----------------------------- Set Deaultvalue ---------------------------- */
  useEffect(() => {
    if (!contextData) {
      let updateData = staticData.map((item) => {
        return { ...item, defaultValue: bodyInfo[item.type] };
      });
      setStaticData(updateData);
      setContextData(true);
    }
    //
  }, [bodyInfo, contextData, staticData]);

  /* ------------------------------ Pick Data Db ------------------------------ */
  useEffect(() => {
    if (contextData) {
      questionsDb.map((item) => {
        if (item.page === "specific-info") {
          const updatedQuestions = item.questions.map((question) => {
            const newData = staticData.find(
              (data) => data.type === question.type
            );
            return newData
              ? { ...question, defaultValue: newData?.defaultValue }
              : question;
          });
          setData(updatedQuestions);
        }

        return item;
      });
    }
  }, [checkupPage, contextData, staticData]);

  const userDataAdd = useCallback(
    async (userPorifleIds) => {
      await axios
        .put(`https://api.medcheckup.az/api/user-data/add/${token}/?lang=en`, {
          user_profiles: userPorifleIds,
        })
        .then((res) => {
          setCheckupPage("select-region");
        })
        .catch((err) => console.log(err));
    },
    [setCheckupPage, token]
  );

  // Start Check Up Post Request
  const userProfileSetPrimayData = useCallback(async () => {
    const startProcedureBody = {
      age: Number(bodyInfo.age),
      weight: Number(bodyInfo.weight),
      height: Number(bodyInfo.height),
      sex: gender,
    };

    await axios
      .post(
        `https://api.medcheckup.az/api/user-profile/${token}/?lang=en`,
        startProcedureBody
      )
      .then((res) => {
        let userPorifleIds = res.data.map((profile) => profile.id);
        userDataAdd(userPorifleIds);
      })
      .catch((err) => console.log(err));
  }, [
    bodyInfo.age,
    bodyInfo.height,
    bodyInfo.weight,
    gender,
    token,
    userDataAdd,
  ]);

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      setCheckupPage("gender-select");
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, setCheckupPage]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      // setCheckupPage("select-region");
      userProfileSetPrimayData();
    };

    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [nextButtonRef, setCheckupPage, userProfileSetPrimayData]);
  // Start Evaluation

  return (
    <div className="specific-info">
      {data &&
        data.map((item) => (
          <BodyInfo
            questionText={item[`question_${i18n.language}`]}
            type={item.type}
            max={item.max}
            defaultValue={item?.defaultValue}
            key={item.type}
          />
        ))}
    </div>
  );
};

export default SpecificInfo;
