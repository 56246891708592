import { useContext } from "react";
import back from "../assets/img/Icons/back-arrow.svg";
import { Link } from "react-router-dom";
import { MainContext } from "../utils/MainContext";

const NotFoundPage = () => {
  // Global State
  const {
    setCheckupPage,
    setBodyInfo,
    setGender,
    setPersonal,
    setSideBarCaption,
    setPrimarySymtiomData,
    setPrimarySymtiomAge,
    setSymptomList,
    setContinent,
    setPainlevel,
    setNextButtonRef,
    setPrevButtonRef,
    setStep,
  } = useContext(MainContext);
  return (
    <div className="not-found">
      <div className="pattern"></div>
      <div className="container">
        <div className="row">
          <div className="number">404</div>
          <Link
            className="back-btn"
            to="/"
            onClick={() => {
              setCheckupPage("general-info");
              setBodyInfo({
                age: "18",
                weight: "50",
                height: "150",
              });
              setGender("");
              setPersonal("");
              setSideBarCaption(null);
              setPrimarySymtiomData([]);
              setPrimarySymtiomAge("");
              setSymptomList({
                prevInnerSymptom: null,
                currentInnerSymptom: null,
                nextInnerSymptom: null,
                list: [],
              });
              setContinent([]);
              setPainlevel(1);
              setNextButtonRef([]);
              setPrevButtonRef([]);
              setStep(1);
            }}
          >
            <img src={back} alt="back" />
            Əsas səhifəya qayıt
          </Link>
        </div>
      </div>
      <div className="pattern"></div>
    </div>
  );
};

export default NotFoundPage;
