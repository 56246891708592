/* ---------------------------------- React --------------------------------- */
import { useCallback, useContext, useEffect, useState } from "react";

/* ----------------------------- React Hook Form ---------------------------- */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

/* --------------------------------- Context -------------------------------- */
import { MainContext } from "../../utils/MainContext";

import { useTranslation } from "react-i18next";
import CheckupCaption from "./CheckupCaption";

const DynamicSymptom = () => {
  /* ------------------------------- Local State ------------------------------ */
  const [questionType, setQuestionType] = useState("checkbox");
  const [checkboxError, setCheckboxError] = useState(false);
  const [renderData, setRenderData] = useState([]);

  /* --------------------------------- Context -------------------------------- */
  const {
    nextButtonRef,
    prevButtonRef,
    setCheckupPage,
    symptomList,
    setSymptomList,

    // Function
    prevPageDirectroy,
    nextPageDirectroy,
  } = useContext(MainContext);

  // Language
  const { t } = useTranslation();

  // Set Type

  /* ------------------------------- Yup Schema ------------------------------- */
  // Radio Schema
  const radioSchema = object({ radio: string().trim() });

  // Checkbox Schema
  const checkboxSchema = object(
    renderData.reduce((acc, item) => {
      acc[item.id] = string().trim();
      return acc;
    }, {})
  );

  /* ----------------------------- React Hook Form ---------------------------- */
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(
      questionType === "checkbox" ? checkboxSchema : radioSchema
    ),
  });

  // Check Data
  useEffect(() => {
    reset();
    if (
      symptomList.list[symptomList.currentInnerSymptom].widget_for_question ===
        "('radio_buttons', 'radio_buttons')" ||
      symptomList.list[symptomList.currentInnerSymptom].widget_for_question ===
        "radio_buttons"
    ) {
      setQuestionType("radio");

      setRenderData(
        symptomList.list[symptomList.currentInnerSymptom].inner_symptoms
      );
      symptomList.list[symptomList.currentInnerSymptom].inner_symptoms.map(
        (item) => item.select && setValue("radio", String(item.id))
      );
    } else {
      setQuestionType("checkbox");

      setRenderData(
        symptomList.list[symptomList.currentInnerSymptom].inner_symptoms
      );
      symptomList.list[symptomList.currentInnerSymptom].inner_symptoms.map(
        (item) => item.select && setValue(String(item.id), String(item.id))
      );
    }
  }, [reset, setValue, symptomList.currentInnerSymptom, symptomList.list]);

  /* ------------------------------- Submit Data ------------------------------ */
  const onSubmit = useCallback(
    (data) => {
      if (questionType === "checkbox") {
        const allFalse = Object.values(data).every(
          (value) => value === "false"
        );

        if (allFalse) {
          setCheckboxError(true);
        } else {
          setCheckboxError(false);

          const selectedDatas = Object.values(data).filter(
            (value) => value !== "false"
          );

          // Reset Selected Element In Array
          let resetSelected = symptomList.list[
            symptomList.currentInnerSymptom
          ].inner_symptoms.map((item) => {
            const { select, ...rest } = item;
            return rest;
          });

          // New Set Data In Local State
          let updateInnerData = resetSelected.map((subsymptom) => {
            if (selectedDatas.includes(subsymptom.id.toString())) {
              return { ...subsymptom, select: true };
            }
            return subsymptom;
          });


          let generalDatas=symptomList.list
          generalDatas[symptomList.currentInnerSymptom] = {
            ...generalDatas[symptomList.currentInnerSymptom], // Mevcut objeyi koru
            inner_symptoms: updateInnerData, // Sadece 'inner' key'ini değiştir
          };
          
  

          // New Data Merge Global Data
          setSymptomList((prevState) => {
            const updatedList = [...prevState.list]; // Mevcut listeyi kopyala
            updatedList[prevState.currentInnerSymptom] = {
              ...updatedList[prevState.currentInnerSymptom], // İlgili objeyi kopyala
              inner_symptoms: updateInnerData, // inner_symptoms'u güncelle
            };

            return {
              ...prevState,
              list: updatedList, // Güncellenmiş listeyi set et
            };
          });

          nextPageDirectroy(symptomList.currentInnerSymptom + 1,generalDatas);
        }
      } else if (questionType === "radio") {
        // Reset Selected Element In Array
        let resetSelected = symptomList.list[
          symptomList.currentInnerSymptom
        ].inner_symptoms.map((item) => {
          const { select, ...rest } = item;
          return rest;
        });

        // New Set Data In Local State
        let updateInnerData = resetSelected.map((subsymptom) => {
          if (subsymptom.id === Number(data.radio)) {
            return { ...subsymptom, select: true };
          }
          return subsymptom;
        });

        let generalDatas=symptomList.list
        generalDatas[symptomList.currentInnerSymptom] = {
          ...generalDatas[symptomList.currentInnerSymptom], // Mevcut objeyi koru
          inner_symptoms: updateInnerData, // Sadece 'inner' key'ini değiştir
        };

        // New Data Merge Global Data
        setSymptomList((prevState) => {
          const updatedList = [...prevState.list]; // Mevcut listeyi kopyala
          updatedList[prevState.currentInnerSymptom] = {
            ...updatedList[prevState.currentInnerSymptom], // İlgili objeyi kopyala
            inner_symptoms: updateInnerData, // inner_symptoms'u güncelle
          };

          return {
            ...prevState,
            list: updatedList, // Güncellenmiş listeyi set et
          };
        });

        // Next Question
        nextPageDirectroy(symptomList.currentInnerSymptom + 1,generalDatas);



    

      } else {
        return null;
      }
      /* --------------------- radio olduqda data baxilmasina --------------------- */
    },
    [
      nextPageDirectroy,
      questionType,
      setSymptomList,
      symptomList.currentInnerSymptom,
      symptomList.list,
    ]
  );
  // questionType, setCheckupPage

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;
    const handleClick = () => {
      prevPageDirectroy(symptomList.currentInnerSymptom - 1);
    };
    if (prevButton) {
      prevButton.addEventListener("click", handleClick);
      return () => {
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [
    prevButtonRef,
    setCheckupPage,
    symptomList.currentInnerSymptom,
    setSymptomList,
    prevPageDirectroy,
  ]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = async () => {
      const result = await handleSubmit(onSubmit)();
      if (result === undefined || errors.radio || checkboxError) {
        return;
      }
    };
    if (nextButton) {
      nextButton.addEventListener("click", handleClick);
      return () => {
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [
    nextButtonRef,
    handleSubmit,
    errors.radio,
    checkboxError,
    onSubmit,
  ]);

  return (
    <>
      {symptomList.list[symptomList.currentInnerSymptom]
        .question_for_inner_symptoms && (
        <CheckupCaption
          title={
            symptomList.list[symptomList.currentInnerSymptom]
              .question_for_inner_symptoms
          }
        />
      )}

      <div className="dynamic-symptom">
        <p className="alert">{t("general.select_row")}</p>
        <form className="dynamic-form" onSubmit={handleSubmit(onSubmit)}>
          {renderData.map((item) => (
            <div className="form-group" key={item.id}>
              <input
                type={questionType === "checkbox" ? "checkbox" : "radio"}
                name={questionType === "checkbox" ? item.id : "radio"}
                id={item.id}
                className={
                  questionType === "checkbox"
                    ? checkboxError
                      ? "checkbox "
                      : "checkbox"
                    : errors.radio
                    ? "radio-inp error"
                    : "radio-inp"
                  // questionType === "checkbox"
                  //   ? checkboxError
                  //     ? "checkbox error"
                  //     : "checkbox"
                  //   : errors.radio
                  //   ? "radio-inp error"
                  //   : "radio-inp"
                }
                {...register(
                  questionType === "checkbox" ? `${item.id}` : "radio"
                )}
                value={item.id}
                onClick={() => {
                  if (questionType === "checkbox" && checkboxError) {
                    setCheckboxError(false);
                  }
                }}
              />
              <label htmlFor={item.id}>{item.title}</label>
            </div>
          ))}
        </form>
        {questionType === "radio"
          ? errors.radio && (
              <div className="error">
                <p className="text">Choose one</p>
              </div>
            )
          : checkboxError && (
              <div className="error">
                <p className="text">At least one must be selected</p>
              </div>
            )}
      </div>
    </>
  );
};

export default DynamicSymptom;
// /* ---------------------------------- React --------------------------------- */
// import { useCallback, useContext, useEffect, useState } from "react";

// /* ----------------------------- React Hook Form ---------------------------- */
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { object, string } from "yup";

// /* --------------------------------- Context -------------------------------- */
// import { MainContext } from "../../utils/MainContext";

// /* -------------------------------- DataBase -------------------------------- */
// // test
// import staticSymptom from "../../db/staticSymptom";
// import { useTranslation } from "react-i18next";
// import CheckupCaption from "./CheckupCaption";

// const DynamicSymptom = () => {
//   /* ------------------------------- Local State ------------------------------ */
//   const [questionType, setQuestionType] = useState("checkbox");
//   const [checkboxError, setCheckboxError] = useState(false);
//   const [renderData, setRenderData] = useState([]);

//   /* --------------------------------- Context -------------------------------- */
//   const { nextButtonRef, prevButtonRef, setCheckupPage, symptomList } =
//     useContext(MainContext);

//   // Language
//   const { t } = useTranslation();

//   // Set Type
//   useEffect(() => {
//     if (
//       symptomList.list[symptomList.currentInnerSymptom].widget_for_question ===
//       "('radio_buttons', 'radio_buttons')"
//     ) {
//       setQuestionType("radio");
//     } else {
//       setQuestionType("checkbox");
//     }
//     setRenderData(
//       symptomList.list[symptomList.currentInnerSymptom].inner_symptoms
//     );
//   }, [symptomList.currentInnerSymptom, symptomList.list]);

//   /* ------------------------------- Yup Schema ------------------------------- */
//   // Radio Schema
//   const radioSchema = object({ radio: string().trim() });

//   // Checkbox Schema
//   const checkboxSchema = object(
//     staticSymptom.reduce((acc, item) => {
//       acc[item.key] = string().required().trim();
//       return acc;
//     }, {})
//   );

//   /* ----------------------------- React Hook Form ---------------------------- */
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     // setValue,
//   } = useForm({
//     resolver: yupResolver(
//       questionType === "checkbox" ? checkboxSchema : radioSchema
//     ),
//   });

//   /* ------------------------------- Submit Data ------------------------------ */
//   const onSubmit = useCallback(
//     (data) => {
//       if (questionType === "checkbox") {
//         Object.keys(data).forEach((key) => {
//           if (data[key] !== "false") {
//             setCheckboxError(false);
//             // console.log(data);
//             // setCheckupPage("yes-no-question");
//           } else {
//             setCheckboxError(true);
//           }
//         });
//         console.log(data);
//       } else if (questionType === "radio") {
//         console.log(data);
//         // setCheckupPage("yes-no-question");
//       } else {
//         return null;
//       }
//       /* --------------------- radio olduqda data baxilmasina --------------------- */
//     },
//     [questionType, setCheckupPage]
//   );

//   /* ---------------------------- Prev Button Usage --------------------------- */
//   useEffect(() => {
//     const prevButton = prevButtonRef && prevButtonRef.current;
//     const handleClick = () => {
//       if (symptomList.prevInnerSymptom === null) {
//         setCheckupPage("select-symptom");
//       }
//     };
//     if (prevButton) {
//       prevButton.addEventListener("click", handleClick);
//       return () => {
//         prevButton.removeEventListener("click", handleClick);
//       };
//     }
//   }, [
//     prevButtonRef,
//     handleSubmit,
//     setCheckupPage,
//     symptomList.prevInnerSymptom,
//   ]);

//   /* ---------------------------- Next Button Usage --------------------------- */
//   useEffect(() => {
//     const nextButton = nextButtonRef && nextButtonRef.current;

//     const handleClick = async () => {
//       const result = await handleSubmit(onSubmit)();
//       if (result === undefined || errors.radio || checkboxError) {
//         return;
//       }
//     };
//     if (nextButton) {
//       nextButton.addEventListener("click", handleClick);
//       return () => {
//         nextButton.removeEventListener("click", handleClick);
//       };
//     }
//   }, [
//     nextButtonRef,
//     handleSubmit,
//     setCheckupPage,
//     errors.radio,
//     checkboxError,
//     questionType,
//     onSubmit,
//   ]);

//   return (
//     <>
//       {symptomList.list[symptomList.currentInnerSymptom]
//         .question_for_inner_symptoms && (
//         <CheckupCaption
//           title={
//             symptomList.list[symptomList.currentInnerSymptom]
//               .question_for_inner_symptoms
//           }
//         />
//       )}

//       <div className="dynamic-symptom">
//         <p className="alert">{t("general.select_row")}</p>
//         <form className="dynamic-form" onSubmit={handleSubmit(onSubmit)}>
//           {staticSymptom.map((item) => (
//             <div className="form-group" key={item.key}>
//               <input
//                 type={questionType === "checkbox" ? "checkbox" : "radio"}
//                 name={questionType === "checkbox" ? item.key : "radio"}
//                 id={item.key}
//                 className={
//                   questionType === "checkbox"
//                     ? checkboxError
//                       ? "checkbox error"
//                       : "checkbox"
//                     : errors.radio
//                     ? "radio-inp error"
//                     : "radio-inp"
//                 }
//                 {...register(
//                   questionType === "checkbox" ? `${item.key}` : "radio"
//                 )}
//                 value={item.key}
//                 onClick={() => {
//                   if (questionType === "checkbox" && checkboxError) {
//                     setCheckboxError(false);
//                   }
//                 }}
//               />
//               <label htmlFor={item.key}>{item.symptom}</label>
//             </div>
//           ))}
//         </form>
//         {questionType === "radio"
//           ? errors.radio && (
//               <div className="error">
//                 <p className="text">Choose one</p>
//               </div>
//             )
//           : checkboxError && (
//               <div className="error">
//                 <p className="text">At least one must be selected</p>
//               </div>
//             )}
//       </div>
//     </>
//   );
// };

// export default DynamicSymptom;
