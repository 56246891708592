import React from "react";
import HomeCaption from "../home-section/HomeCaption";
import HomeCard from "../../component/HomeCard";
import homeTest2 from "../../db/homeTestDb2";

const Reference = () => {
  return (
    <section className="reference">
      <div className="container">
        <div className="head">
          <HomeCaption
            title={"The Best Reference"}
            detail={
              "Problems trying to resolve the conflict between the two major realms of Classical physics: Newtonian mechanics "
            }
          />
        </div>
        <div className="body">
          <div className="row">
            {homeTest2.map((item) => (
              <HomeCard key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reference;
