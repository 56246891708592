import { useCallback, useContext, useEffect, useState } from "react";
import close from "../../assets/img/Icons/close-circle.svg";
import tick from "../../assets/img/Icons/tick-bold.svg";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";
const SideBar = () => {
  const {
    symptomBar,
    setSymptomBar,
    sideBarCaption,
    setSideBarCaption,
    bodyParts,
    symptomList,
    setSymptomList,
  } = useContext(MainContext);

  // test
  const [data, setData] = useState([
    { id: 1, symptom: "Head" },
    { id: 4, symptom: "leg" },
    { id: 3, symptom: "Upper abdomen" },
    { id: 6, symptom: "chest" },
    { id: 2, symptom: "Eyes" },
  ]);
  const [bodySymptomList, setBodySymptomList] = useState([]);
  const [staticData, setStaticData] = useState([
    { id: 1, title: "Head" },
    { id: 4, title: "Eyes" },
    { id: 3, title: "Ears" },
    { id: 6, title: "Nose" },
    { id: 2, title: "Oral cavity" },
  ]);
  const[symptomListIds,setSymptomListIds]=useState([])

  // Check Symptom Exist
useEffect(()=>{
  if(symptomBar){
    setSymptomListIds(symptomList.list.map(item=>item.id))
  }
},[symptomBar, symptomList.list])

  // useEffect(() => {
  //   if (sideBarCaption === "Select the exact location on the body") {
  //     setBodySymptomList(staticData);
  //   }
  // }, [sideBarCaption, staticData]);

  const handlelick = (newSymptom) => {
    if (sideBarCaption !== "Select the exact location on the body") {





      const isExist = symptomList.list?.some(
        (symptom) => symptom.id === newSymptom.id
      );
  
        if (!isExist) {
          setSymptomList((prevState) => ({
            ...prevState,
            list: [...prevState.list, newSymptom],
          }));
        }else{
          const updatedSymptomList = symptomList.list.filter(
            (item) => item.id !== newSymptom.id
          );
          setSymptomList({ ...symptomList, list: updatedSymptomList });
        }









   
      // if (symptomList.list.includes(symptom)) {
      //   setSymptomList((prevState) => ({
      //     ...prevState,
      //     list: prevState.list.filter((key) => key !== symptom),
      //   }));
      // } else {
      //   setSymptomList((prevState) => ({
      //     ...prevState,
      //     list: [...prevState.list, String(symptom)],
      //   }));
      // }
    } else {
      setSideBarCaption(newSymptom.title);
    }
  };

  const getBodyPartSymptomsRequest = useCallback(
    async (bodyId) => {
   
      await axios
        .get(
          `https://api.medcheckup.az/api/bodypart/${bodyId}/symptoms/?lang=en`
        )
        .then((res) => {
          
          setBodySymptomList(res.data.results)})
        .catch((err) => console.log(err));
    },
    [setBodySymptomList]
  );

  useEffect(() => {
    if (symptomBar && sideBarCaption) {
      if (sideBarCaption === "Select the exact location on the body") {
        setBodySymptomList([
          { id: 1, title: "Head" },
          { id: 4, title: "Eyes" },
          { id: 3, title: "Ears" },
          { id: 6, title: "Nose" },
          { id: 2, title: "Oral cavity" },
        ]);
      }else{

        setBodySymptomList(["loading"]);
      }
      let specificBodyPart = bodyParts.find(
        (parts) => parts.title === sideBarCaption
      );
      if (specificBodyPart) {
        getBodyPartSymptomsRequest(specificBodyPart.id);
      }
    }
  }, [getBodyPartSymptomsRequest, bodyParts, sideBarCaption, symptomBar]);

  return (
    <div className={symptomBar ? " side-bar isOpenMenu scroll" : "side-bar scroll"}>
      <div className="top">
        <h3 className="body-part">{sideBarCaption}</h3>
        <div
          className="close-icon"
          onClick={() => {
            setSymptomBar(false);
          }}
        >
          <img src={close} alt="close-icon" />
        </div>
      </div>
      <div className="bottom ">
        <ul className="symptom-list " >
          {bodySymptomList[0] === "loading" ? (
            <li className="symptom-item">Loading</li>
          ) : bodySymptomList.length === 0 ? (
            <li className="symptom-item">Not Found</li>
          ) : (
            bodySymptomList.map((item) => (
              <li
                className="symptom-item"
                key={item.id}
                onClick={() => handlelick(item)}
              >
                <span>{item.title}</span>
                {symptomListIds.includes(item.id) &&
                  sideBarCaption !==
                    "Select the exact location on the body" && (
                    <div className="tick">
                      <img src={tick} alt="tick" />
                    </div>
                  )}
                {/* {sideBarCaption === "Select the exact location on the body" && (
                  <div className="tick">
                    <img src={tick} alt="tick" />
                  </div>
                )} */}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
