import { Link } from "react-router-dom";
import logo from "../assets/img/huge-logo.svg";
import unHide from "../assets/img/Icons/unhide.svg";
import hide from "../assets/img/Icons/hide.svg";
const SignUp = () => {
  return (
    <main>
      <section className="form-page">
        <div className="container">
          <div className="row">
            <div className="left-side">
              <div  className="logo-box">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            <form className="right-side">
              <div className="info-group">
                <h1 className="caption">Create an account</h1>
                <p className="sub-title">
                  The customer is very happy, it will be followed customer
                  service. The most important disease
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="Full name">Full Name</label>
                <input type="text" />
              </div>
              <div className="form-group">
                <label htmlFor="Full name">Email</label>
                <input type="text" />
              </div>
              <div className="form-group">
                <label htmlFor="Full name">Phone</label>
                <input type="text" />
              </div>
              <div className="form-group">
                <div className="top">
                  <label htmlFor="Full name">Password</label>
                  <button className="icon">
                      <img src={hide} alt="unhide" />
                    </button>
                </div>
                <input type="text" />
              </div>
              <div className="alert-area">
                <div className="alert-group">
                  <p className="detail">Use 8 or more characters</p>
                  <p className="detail">
                    Use upper and lower case letters (e.g. Aa)
                  </p>
                </div>
                <div className="alert-group">
                  <p className="detail">Use a number (e.g. 1234)</p>
                  <p className="detail">Use a symbol (e.g. !@#$)</p>
                </div>
              </div>
              <div className="form-group">
                <div className="top">
                  <label htmlFor="Full name">Repassword</label>
                  <button className="icon">
                      <img src={unHide} alt="unhide" />
                    </button>
                </div>
                <input type="text" />
              </div>

              <button className="submit">Sing up</button>
              <p className="login-adviser">
                Do you have an account? <Link to="/login">Log in</Link>
              </p>
              <h2 className="alert-title">Other sing up accounts</h2>
              <h3 className="alert-sub-title">
                If you already have an account on these platforms, you don't
                need to register.
              </h3>
              <div className="related-website">
                <Link to="#">Vaccine App</Link>
                <Link to="#">Videodoctor</Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SignUp;
