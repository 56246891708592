import React from 'react'

const SeacrhLoadingItem = () => {
  return (
    <li className="seacrh-loading-item">
    <span className="text"></span>
  </li>
  )
}

export default SeacrhLoadingItem