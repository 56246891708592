import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import SeacrhLoadingItem from "./SeacrhLoadingItem";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

const SearchSymptom = () => {
  const { symptomList, setSymptomList } = useContext(MainContext);

  const [searchContentVisible, setSearchContentVisible] = useState(false);
  const [data, setData] = useState([]);

  // Language
  const { t } = useTranslation();

  const searchRequest = useCallback(async (value) => {
    await axios
      .get(
        `https://api.medcheckup.az/api/bodypart/all/symptoms/?search=${value}&lang=en`
      )
      .then((res) => {
        setData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  const getAllDataRequest = useCallback(async () => {
    await axios
      .get(`https://api.medcheckup.az/api/bodypart/all/symptoms/?lang=en`)
      .then((res) => {
        //         console.log(res.data.results
        // )
        // pagination var deyesen
        setData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleCheck = (value) => {
    setData(["loader"]);
    if (String(value).length > 2) {
      searchRequest(value);
      console.log(value);
    } else {
      getAllDataRequest();
      // setSearchContentVisible(false);
    }
  };

  // Firstly Render
  useEffect(() => {
    setData(["loader"]);
    getAllDataRequest();
  }, [getAllDataRequest]);

  // Add Symptom in list

  // const addSymptom = (symptom) => {
  //   console.log(symptom)
  //   if (symptomList.length !== 0) {
  //     symptomList.map((listItem) => {
  //       console.log(listItem)
  //       if (listItem.id === symptom.id) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     // setKeywords(keywords.filter((key) => key !== symptom));
  //   } else {

  //     // setSymptomList([...symptomList, symptom]);
  //     setSymptomList((prevState) => ({
  //       ...prevState,
  //       page_data: [symptom, ...prevState],
  //     }));
  //   }
  // };
  const addSymptom = (newSymptom) => {
    // Belirli bir anahtara göre (örneğin id) kontrol yapabilirsiniz
    const isExist = symptomList.list?.some(
      (symptom) => symptom.id === newSymptom.id
    );

    if (!isExist) {
      // setSymptomList([...symptomList, newSymptom]);
      if (!isExist) {
        setSymptomList((prevState) => ({
          ...prevState,
          list: [...prevState.list, newSymptom],
        }));
      }
    }
  };
  return (
    <div className="seacrh-symptom">
      <h6 className="title">
      {t("select_symptom.caption")}
      </h6>
      <form className="seacrh-form" onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          className="seacrh-inp"
          name="search"
          placeholder={t("select_symptom.input_placeholder")}
          autoComplete="off"
          onFocus={() => setSearchContentVisible(true)}
          onChange={(e) => handleCheck(e.target.value.trim())}
        />
        <ul
          className={
            searchContentVisible ? "search-content active" : "search-content"
          }
        >
          {data[0] === "loader" ? (
            Array.from({ length: 5 }).map((_, i) => (
              <SeacrhLoadingItem key={i} />
            ))
          ) : data.length === 0 ? (
            <li
              className="seacrh-item notFound"
              onClick={() => {
                setSearchContentVisible(false);
              }}
            >
              {t("general.not_found")}
            </li>
          ) : (
            data.map((symptom) => (
              <li
                className="seacrh-item"
                onClick={() => {
                  addSymptom(symptom);
                  setSearchContentVisible(false);
                }}
                key={symptom.id}
              >
                {symptom.title}
              </li>
            ))
          )}
        </ul>
      </form>
      <div
        className={
          searchContentVisible ? "search-overlay active" : "search-overlay"
        }
        onClick={() => {
          setSearchContentVisible(false);
        }}
      ></div>
    </div>
  );
};

export default SearchSymptom;
