import { Link } from "react-router-dom";
import logo from "../assets/img/huge-logo.svg";
import unHide from "../assets/img/Icons/unhide.svg";
import hide from "../assets/img/Icons/hide.svg";
import { useEffect, useState } from "react";
const ForgetPassword = () => {
  // Local State
  const [step, setStep] = useState(1);

  // Reset step
  useEffect(() => {
    setStep(1);
  }, []);
  return (
    <main>
      <section className="form-page">
        <div className="container">
          <div className="row">
            <div className="left-side">
              <div className="logo-box">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            {step === 1 ? (
              <form className="right-side">
                <div className="info-group">
                  <h1 className="caption">Fogot password</h1>
                  <p className="sub-title">
                    The customer is very happy, it will be followed customer
                    service. The most important disease
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="Full name">Email</label>
                  <input type="text" />
                </div>
                <button className="submit" onClick={(e)=>{
                  e.preventDefault()
                  setStep(2)
                }}>Confirm</button>
                <p className="login-adviser">
                  Don’t have an account? <Link to="/login">Login</Link>
                </p>
              </form>
            ) : step === 2 ? (
              <form className="right-side">
                <div className="info-group">
                  <h1 className="caption">Otp</h1>
                  <p className="sub-title">
                    The customer is very happy, it will be followed customer
                    service. The most important disease
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="Full name">Otp Code </label>
                  <input type="text" />
                </div>
                <button className="submit"
                onClick={(e)=>{
                  e.preventDefault()
                  setStep(3)
                }}
                >Confirm Code</button>
              </form>
            ) : (
              <form className="right-side">
                <div className="info-group">
                  <h1 className="caption">Change password</h1>
                  <p className="sub-title">
                    The customer is very happy, it will be followed customer
                    service. The most important disease
                  </p>
                </div>

                <div className="form-group">
                  <div className="top">
                    <label htmlFor="Full name">Password</label>
                    <button className="icon">
                      <img src={hide} alt="unhide" />
                    </button>
                  </div>
                  <input type="text" />
                </div>
                <div className="alert-area">
                  <div className="alert-group">
                    <p className="detail">Use 8 or more characters</p>
                    <p className="detail">
                      Use upper and lower case letters (e.g. Aa)
                    </p>
                  </div>
                  <div className="alert-group">
                    <p className="detail">Use a number (e.g. 1234)</p>
                    <p className="detail">Use a symbol (e.g. !@#$)</p>
                  </div>
                </div>
                <div className="form-group">
                  <div className="top">
                    <label htmlFor="Full name">Repassword</label>
                    <button className="icon">
                      <img src={unHide} alt="unhide" />
                    </button>
                  </div>
                  <input type="text" />
                </div>
                <button className="submit"
                onClick={(e)=>{
                  e.preventDefault()
                  setStep(1)
                }}
                >Change Password</button>
              </form>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
export default ForgetPassword;
