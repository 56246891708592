import { Link } from "react-router-dom";
import arrow from "../../assets/img/Icons/learn-more.svg";
const History = () => {
  return (
    <section className="profile-history">
      <ul className="history-list">
        <li className="history-item">
          <p className="date">Your reply date: 12.12.2023</p>
          <Link to="https://videodoktor.az/" target="_blank">
            Show
            <span className="icon">
              <img src={arrow} alt="icon" />
            </span>
          </Link>
        </li>
        <li className="history-item">
          <p className="date">Your reply date: 12.12.2023</p>
          <Link to="https://videodoktor.az/" target="_blank">
            Show
            <span className="icon">
              <img src={arrow} alt="icon" />
            </span>
          </Link>
        </li>
        <li className="history-item">
          <p className="date">Your reply date: 12.12.2023</p>
          <Link to="https://videodoktor.az/" target="_blank">
            Show
            <span className="icon">
              <img src={arrow} alt="icon" />
            </span>
          </Link>
        </li>
        <li className="history-item">
          <p className="date">Your reply date: 12.12.2023</p>
          <Link to="https://videodoktor.az/" target="_blank">
            Show
            <span className="icon">
              <img src={arrow} alt="icon" />
            </span>
          </Link>
        </li>
        <li className="history-item">
          <p className="date">Your reply date: 12.12.2023</p>
          <Link to="https://videodoktor.az/" target="_blank">
            Show
            <span className="icon">
              <img src={arrow} alt="icon" />
            </span>
          </Link>
        </li>
        <li className="history-item">
          <p className="date">Your reply date: 12.12.2023</p>
          <Link to="https://videodoktor.az/" target="_blank">
            Show
            <span className="icon">
              <img src={arrow} alt="icon" />
            </span>
          </Link>
        </li>
      </ul>
    </section>
  );
};

export default History;
