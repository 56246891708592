import { useCallback, useContext, useEffect, useState } from "react";

// Context
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../utils/MainContext";

const GeneralPreliminaryInspection = () => {
  /* --------------------------------- Context -------------------------------- */
  const {
    nextButtonRef,
    setCheckupPage,
    prevButtonRef,
    primarySymtiomData,
    setPrimarySymtiomData,
    token,
  } = useContext(MainContext);

  // const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});

  // Navigate
  const navigate = useNavigate();
  // Language
  const { i18n,t } = useTranslation();

  const requestForMainQuestion = useCallback(
    async (previousDataExist, previosData) => {
      await axios
        .get(
          `https://api.medcheckup.az/api/chronic-diseases/${token}/?lang=${i18n.language}`
        )
        .then((res) => {
          if (previousDataExist) {
            const updatedData = previosData.map((item) => {
              const newItem = res.data.results.find(
                (backendItem) => backendItem.id === item.id
              );
              if (newItem) {
                return {
                  ...item,
                  title: newItem.title,
                  question_text: newItem.question_text,
                };
              }
              return item;
            });

            // Son adımda state'i güncelliyoruz
            setPrimarySymtiomData(updatedData);
          } else {
            setPrimarySymtiomData(res.data.results);
          }
          // setPrimarySymtiomData(res.data.results);
        })
        .catch(
          (err) => err && console.log(err)
          // navigate("/error")
        );
    },
    [i18n.language, setPrimarySymtiomData, token]
  );

  // Render data
  useEffect(() => {
    if (i18n.language) {
      requestForMainQuestion(
        primarySymtiomData.length !== 0 ? true : false,
        primarySymtiomData ?? ""
      );
    }
    // Depends dont fix!/ only depends i18n.language
  }, [i18n.language]);

  // Set Value
  const handleChange = (e, key) => {
    const newStaticSymptom = primarySymtiomData.map((item) => {
      if (item.id === key) {
        return { ...item, select: e.target.value };
      }
      return item;
    });
    const newErrors = { ...errors };
    if (newErrors[key]) {
      delete newErrors[key];
    }
    setPrimarySymtiomData(newStaticSymptom);
    setErrors(newErrors);
  };

  // Validation Empty Input
  const validateSelections = useCallback(() => {
    const newErrors = {};
    primarySymtiomData.forEach((item) => {
      if (!item.select) {
        newErrors[item.id] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [primarySymtiomData]);

  // Submit form / Put Request for user Data Update
  const handleSubmit = useCallback(async () => {
    if (validateSelections()) {
      let chronicDiseasesIds=primarySymtiomData.filter(symtom=>symtom.select==="yes" && symtom.id).map(symtom => symtom.id); 
      await axios
        .put(`https://api.medcheckup.az/api/user-data/add/${token}/?lang=en`, {
          chronic_diseases: chronicDiseasesIds,
        })
        .then((res) => {
          res && setCheckupPage("select-symptom");
        })
        .catch((err) => console.log(err));
    }
  }, [primarySymtiomData, setCheckupPage, token, validateSelections]);

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      setCheckupPage("select-region");
      // setStaticSymptomData(data);
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, setCheckupPage]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      handleSubmit();
    };

    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [handleSubmit, nextButtonRef]);

  return (
    <div className="static-dynamic-symptom">
      <p className="alert">{t("general.select_row")}</p>
      <form className="symptom-form">
        {primarySymtiomData.length !== 0 &&
          primarySymtiomData.map((item) => (
            <div className="form-group" key={item.id}>
              <label className="symptom" htmlFor={item.id}>
                {item.question_text}
              </label>
              <div className="inp-area">
                <div className="inp-group">
                  <input
                    type="radio"
                    name={item.id}
                    className={
                      errors[item.id] ? "radio-inp error" : "radio-inp "
                    }
                    value="yes"
                    onChange={(e) => handleChange(e, item.id)}
                    checked={item.select === "yes"}
                  />
                  <span
                    className={errors[item.id] ? "text  textError" : "text"}
                  >
                   {t("general.yes")}
                  </span>
                </div>
                <div className="inp-group">
                  <input
                    type="radio"
                    name={item.id}
                    className={
                      errors[item.id] ? "radio-inp error" : "radio-inp "
                    }
                    value="no"
                    onChange={(e) => handleChange(e, item.id)}
                    checked={item.select === "no"}
                  />
                  <span
                    className={errors[item.id] ? "text  textError" : "text"}
                  >
           {t("general.no")}
                  </span>
                </div>
                <div className="inp-group">
                  <input
                    type="radio"
                    name={item.id}
                    className={
                      errors[item.id] ? "radio-inp error" : "radio-inp "
                    }
                    value="I don’t know"
                    onChange={(e) => handleChange(e, item.id)}
                    checked={item.select === "I don’t know"}
                  />
                  <span
                    className={errors[item.id] ? "text  textError" : "text"}
                  >
                       {t("general.i_dont_know")}
                 
                  </span>
                </div>
              </div>
            </div>
          ))}
      </form>
    </div>
  );
};

export default GeneralPreliminaryInspection;
