// React
import { useContext, useEffect, useState } from "react";

// Component
import SearchSymptom from "./SearchSymptom";
import SearchKeywordsArea from "./SearchKeywordsArea";
import BodyDesktop from "./BodyDesktop";
import BodyMobile from "./BodyMobile";
import SideBar from "./SideBar";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";

const SelectSymptom = () => {
  // Global State
  const {
    symptomBar,
    setSymptomBar,
    prevButtonRef,
    nextButtonRef,
    setCheckupPage,
    bodyParts,
    setBodyParts,

    // Function
    nextPageDirectroy,
  } = useContext(MainContext);

  // Local State
  // Detect device width for render box component
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // All Body Part
  useEffect(() => {
    axios
      .get("https://api.medcheckup.az/api/bodyparts/?lang=en")
      .then((res) => res && setBodyParts(res.data.results))
      .catch((err) => console.log(err));
  }, [setBodyParts]);

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      setCheckupPage("general-preliminary-inspection");
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, setCheckupPage]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      nextPageDirectroy(0);
    };

    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [nextButtonRef, nextPageDirectroy]);

  return (
    <div className="select-symptom">
      <div className="left-side">
        <SearchSymptom />
        <SearchKeywordsArea />
      </div>
      <div className="right-side">
        {windowWidth > 1000 ? <BodyDesktop /> : <BodyMobile />}
      </div>
      <SideBar />
      <div
        className={symptomBar ? "overlay active" : "overlay"}
        onClick={() => setSymptomBar(false)}
      ></div>
    </div>
  );
};

export default SelectSymptom;
