import React, { useContext } from "react";
import ProfileSideBar from "../component/ProfileSideBar";
import Account from "./profile-section/Account";
import ChangePassword from "./profile-section/ChangePassword";
import History from "./profile-section/History";
import { MainContext } from "../utils/MainContext";

const Profile = () => {
  const { profileActivePage, setProfileActivePage } = useContext(MainContext);

  return (
    <main>
      <section className="profile">
        <div className="container">
          <div className="row">
            <ProfileSideBar />

            {profileActivePage === "Account" ? (
              <Account />
            ) : profileActivePage === "Password change" ? (
              <ChangePassword />
            ) : profileActivePage === "History" ? (
              <History />
            ) : null}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Profile;
