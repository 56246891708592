import axios from "axios";
import { createContext, useCallback, useState } from "react";

export const MainContext = createContext();

export const AppContext = ({ children }) => {
  // Sesion Token
  const [token, setToken] = useState("");

  /* ---------------------------- Complete Checkup ---------------------------- */
  const [complete, setComplete] = useState(false);

  /* ---------------------------- Component Render ---------------------------- */
  const [checkupPage, setCheckupPage] = useState("general-info");

  /* ------------------------------ Specific Info ----------------------------- */
  const [bodyInfo, setBodyInfo] = useState({
    age: "18",
    weight: "50",
    height: "150",
  });
  const [gender, setGender] = useState("");
  const [personal, setPersonal] = useState("");

  /* -------------------------- SideBar Open && close ------------------------- */
  const [symptomBar, setSymptomBar] = useState();
  const [sideBarCaption, setSideBarCaption] = useState(null);

  /* ------------------------ General Preliminary Inspection Data ------------------------ */

  const [primarySymtiomData, setPrimarySymtiomData] = useState([]);

  /* ---------------------------- Select Symptom List ---------------------------- */
  // const [symptomList, setSymptomList] = useState([]);
  const [symptomList, setSymptomList] = useState({
    prevInnerSymptom: null,
    currentInnerSymptom: null,
    nextInnerSymptom: null,
    list: [],
  });
  /* ---------------------------- Question List ---------------------------- */
  const [questionList, setQuestionList] = useState([]);
  const [questionStep, setQuestionStep] = useState(0);
  // const [questionList, setQuestionList] = useState({
  //   prevInnerSymptom: null,
  //   currentInnerSymptom: null,
  //   nextInnerSymptom: null,
  //   list: [],
  // });

  /* --------------------------------- Region --------------------------------- */
  const [continent, setContinent] = useState([]);
  const [selectRegionObj, setSelectRegionObj] = useState([]);

  /* -------------------------------- BodyParts ------------------------------- */
  const[bodyParts,setBodyParts]=useState([])

  /* -------------------------------- PainLevel ------------------------------- */
  const [painLevel, setPainlevel] = useState(1);

  /* ------------------------------ Video Visible ----------------------------- */
  const [videoVisible, setVideoVisible] = useState(false);

  /* -------------------------- Next Prev Button Ref -------------------------- */
  const [nextButtonRef, setNextButtonRef] = useState([]);
  const [prevButtonRef, setPrevButtonRef] = useState([]);

  /* ---------------------------------- Step ---------------------------------- */
  const [step, setStep] = useState(1);

  // Profile
  const[profileActivePage,setProfileActivePage]=useState("Account")

  /* -------------------------------- Function -------------------------------- */
  // Remove Data in StmptomList
  const removeSymptomInList = (symptomId) => {
    const updatedSymptomList = symptomList.list.filter(
      (item) => item.id !== symptomId
    );
    setSymptomList({ ...symptomList, list: updatedSymptomList });
  };

  const questionGetFirstRaundData = useCallback(async () => {
    await axios
      .get(`https://api.medcheckup.az/api/questions/${token}/1/?lang=en`)
      .then((res) => {
        // if (res.data.results.length !== 0){

          setQuestionList([{ step: 1, list: res.data.results }]);
   
          setQuestionStep(0);
          setCheckupPage("question");
        // }else {
        //   setCheckupPage("result");
        //   setComplete(true);
        // }
      })
      .catch((err) => console.log(err));
  }, [setCheckupPage, setQuestionList, token]);

  const userDataAddSymptom = async (allSymtom) => {
    // let symptomIds = allSymtom.map((symptom) => symptom.id);
    let checkboxList=symptomList.list.filter((symptom) => symptom.widget_for_question === "checkbox" && symptom )

    let radioList = allSymtom.filter(
      (symptom) =>
        (symptom.widget_for_question === "('radio_buttons', 'radio_buttons')" ||
          symptom.widget_for_question === "radio_buttons") &&
        symptom
    );
    if (radioList) {
      var radioSubSymptomIds = radioList
        ?.map(
          (symptom) =>
            symptom.inner_symptoms
              .filter((subsymptom) => subsymptom.select) // select olanları filtrele
              .map((subsymptom) => subsymptom.id) // sadece id değerlerini al
        )
        .flat();
    }
    if (radioList) {
      var checkboxSubSymptomIds = checkboxList
        ?.map(
          (symptom) =>
            symptom.inner_symptoms
              .filter((subsymptom) => subsymptom.select) // select olanları filtrele
              .map((subsymptom) => subsymptom.id) // sadece id değerlerini al
        )
        .flat();
    }

// test radio
const generalArr = radioList.map(item=>item.id).concat(radioSubSymptomIds);

    await axios
      .put(`https://api.medcheckup.az/api/user-data/add/${token}/?lang=en`, {
        symptoms: generalArr,
      })
      .then((res) => res && questionGetFirstRaundData())
      .catch((err) => console.log(err));
  };

  // Chec Data For Prev Page
  const prevPageDirectroy = (currentPageIndex) => {
    if (currentPageIndex !== -1) {
      if (
        symptomList.list[currentPageIndex].widget_for_question === "checkbox"
      ) {
        setCheckupPage("dynamic-symptom");
        // complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "temprature"
      ) {
        setCheckupPage("temprature");
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "pain_level"
      ) {
        setCheckupPage("pain-level");
        // Complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "yes_no"
      ) {
        setCheckupPage("yes-no-question");
        // Complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question ===
          "radio_buttons" ||
        symptomList.list[currentPageIndex].widget_for_question ===
          "('radio_buttons', 'radio_buttons')"
      ) {
        setCheckupPage("dynamic-symptom");
      } else {
        // reset all compnent and direct error page
      }
      setSymptomList((prevState) => ({
        ...prevState,
        currentInnerSymptom: currentPageIndex,
      }));

      // widget_for_question
    } else {
      setCheckupPage("select-symptom");
    }
  };

  // Chec Data For Next Page
  const nextPageDirectroy = (currentPageIndex, symptoms = []) => {
    if (currentPageIndex !== symptomList.list.length) {
      if (
        symptomList.list[currentPageIndex].widget_for_question === "checkbox"
      ) {
        setCheckupPage("dynamic-symptom");
        // complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "temprature"
      ) {
        setCheckupPage("temprature");
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "pain_level"
      ) {
        setCheckupPage("pain-level");
        // Complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "yes_no"
      ) {
        setCheckupPage("yes-no-question");
        // Complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question ===
          "radio_buttons" ||
        symptomList.list[currentPageIndex].widget_for_question ===
          "('radio_buttons', 'radio_buttons')"
      ) {
        setCheckupPage("dynamic-symptom");
      } else {
        // reset all compnent and direct error page
      }

      setSymptomList((prevState) => ({
        ...prevState,
        currentInnerSymptom: currentPageIndex,
        // nextInnerSymptom: nextIndex,
      }));

      // widget_for_question
    } else {
      // userData icerisine props kimi id array gondermelisen
      userDataAddSymptom(symptoms);
    }
  };

  const globalStates = {
    // Sesion Token
    token,
    setToken,

    // Export State
    complete,
    setComplete,
    checkupPage,
    setCheckupPage,
    bodyInfo,
    setBodyInfo,
    gender,
    setGender,
    personal,
    setPersonal,

    // GeneralPreliminaryInspection
    primarySymtiomData,
    setPrimarySymtiomData,

    // Question List
    questionList,
    setQuestionList,
    questionStep,
    setQuestionStep,

    symptomBar,
    setSymptomBar,
    sideBarCaption,
    setSideBarCaption,
    symptomList,
    setSymptomList,

    // Region
    continent,
    setContinent,
    selectRegionObj,
    setSelectRegionObj,

    // BodyParts
    bodyParts,setBodyParts,

    painLevel,
    setPainlevel,
    nextButtonRef,
    prevButtonRef,
    setNextButtonRef,
    setPrevButtonRef,
    step,
    setStep,
    videoVisible,
    setVideoVisible,

    // Profile
    profileActivePage,setProfileActivePage,

    // function
    removeSymptomInList,
    nextPageDirectroy,
    prevPageDirectroy,
    // evaluationInitialize,
  };
  return (
    <MainContext.Provider value={globalStates}>{children}</MainContext.Provider>
  );
};
