import React from 'react'

const ChangePassword = () => {
    return (
        <section className="account-password">
          <h1 className="user-name">Kamil Kamilov</h1>
          <form className="personality-information">
            <div className="form-group">
              <label className="label">Enter the previous password</label>
              <input type="password" />
            </div>
            <div className="form-group">
              <label className="label">Create your new password</label>
               <input type="password" />
            </div>
            <div className="form-group">
              <label className="label">Write again your new password</label>
                <input type="password" />
            </div>
            <div className="button-area">
              <button>Change</button>
            </div>
          </form>
        </section>
      );
    };

export default ChangePassword