import React, { useCallback, useContext, useEffect, useState } from "react";
import ResultSymptom from "../../component/result/ResultSymptom";
import ResultGeneralInfo from "../../component/result/ResultGeneralInfo";
import AnswerInfo from "../../component/result/AnswerInfo";
import FeedBack from "../../component/result/FeedBack";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";

const ResultPage = () => {
  // Global State
  const { token } = useContext(MainContext);
  
  // Local State
  const [data,setData]=useState(null)

  const getResult = useCallback(async () => {
    await axios
      .get(`https://api.medcheckup.az/api/diagnose/${token}/?lang=en`)
      .then((res) => {
     setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [token]);

  useEffect(() => {
    getResult();
  }, []);
  return (
    <>
      <ResultGeneralInfo />
      <ResultSymptom data={data} />
      <AnswerInfo data={data} />
      <FeedBack />
    </>
  );
};

export default ResultPage;
