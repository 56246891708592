/* ---------------------------------- React --------------------------------- */
import { useContext } from "react";

/* -------------------------------- Component ------------------------------- */
import ButtonPrev from "../ButtonPrev";
import ButtonNext from "../ButtonNext";

/* --------------------------------- Context -------------------------------- */
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

const CheckUpPagination = () => {
  const { checkupPage, secondButtonRef } = useContext(MainContext);

  // Language
const {t}=useTranslation()
  return (
    <div className="checkup-pagination">
      <ButtonPrev
        image={true}
        text={t("general.back")}
        className={checkupPage === "general-info" ? "deActivate " : ""}
      />
      <ButtonNext
        image={true}
        text={t("general.next")}
        buttonRef={secondButtonRef}
        className={
          checkupPage === "gender-select"
            ? "deActivate "
            : checkupPage === "personal-select"
            ? "deActivate "
            : checkupPage === "yes-no-question" ? "deActivate " : ""
        }
      />
      <span className="line"></span>
    </div>
  );
};

export default CheckUpPagination;
