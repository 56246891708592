import React, { useContext } from "react";
import BodyManMobile from "../checkup-body/BodyManMobile";
import BodyWomanMobile from "../checkup-body/BodyWomanMobile";
import { MainContext } from "../../utils/MainContext";

const BodyMobile = () => {
  const { gender } = useContext(MainContext);
  return <>{gender === "female" ? <BodyWomanMobile /> : <BodyManMobile />}</>;
};

export default BodyMobile;
