import { useCallback, useContext, useEffect, useState } from "react";

// Context
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../utils/MainContext";
const Question = () => {
  /* --------------------------------- Context -------------------------------- */
  const {
    nextButtonRef,
    setCheckupPage,
    prevButtonRef,
    questionList,
    setQuestionList,
    questionStep,
    setQuestionStep,
    setComplete,
    token,
  } = useContext(MainContext);

  // const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [errors, setErrors] = useState({});

  // Navigate
  const navigate = useNavigate();
  // Language
  const { t } = useTranslation();
  useEffect(() => {
    setRenderData(questionList[questionStep].list);
    console.log(questionList[questionStep]);

    // const updatedInnerSymptoms = symptomList.list[symptomList.currentInnerSymptom].inner_symptoms.map(
    //   (item, index) => ({
    //     ...item, // Mevcut objeyi koru
    //     key: `line${index + 1}`, // Yeni key alanını ekle
    //   })
    // );

    // setRenderData(updatedInnerSymptoms);
  }, [questionList, questionStep]);

  // Set Value
  const handleChange = (e, key, subanswerId) => {
    const newStaticSymptom = renderData.map((item) => {
      if (item.id === key) {
        return { ...item, select: e.target.value, selectedId: subanswerId };
      }
      return item;
    });
    const newErrors = { ...errors };
    if (newErrors[key]) {
      delete newErrors[key];
    }

    setRenderData(newStaticSymptom);
    setErrors(newErrors);
  };

  // Validation Empty Input
  const validateSelections = useCallback(() => {
    const newErrors = {};
    renderData.forEach((item) => {
      if (!item.select) {
        newErrors[item.id] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [renderData]);

  const questionGetRaundData = useCallback(async () => {
    await axios
      .get(
        `https://api.medcheckup.az/api/questions/${token}/${
          questionList.length + 1
        }/?lang=en`
      )
      .then((res) => {
        if (res.data.results.length !== 0) {
          setRenderData(res.data.results);
          let newData = {
            step: questionStep + 1,
            list: res.data.results,
          };
          setQuestionList((prevState) => [...prevState, newData]);
          setQuestionStep(questionStep + 1);
        } else {
          setCheckupPage("result");
          setComplete(true);
        }
      })
      .catch((err) => console.log(err));
  }, [
    questionList.length,
    questionStep,
    setCheckupPage,
    setComplete,
    setQuestionList,
    setQuestionStep,
    token,
  ]);

  // Submit form / Put Request for user Data Update
  const handleSubmit = useCallback(async () => {
    if (validateSelections()) {
      const result = renderData.reduce((acc, item) => {
        acc[item.id] = item.selectedId;
        return acc;
      }, {});

      // console.log(result)
      await axios
        .post(`https://api.medcheckup.az/api/answers/${token}/?lang=en`, {
          answers: result,
        })
        .then((res) => {
          console.log(res.data.answers);
          if (res.data.answers === "ok") {
            setQuestionList((prevState) => {
              // prevState üzerinde map ile işlem yapıyoruz
              return prevState.map((obj, index) => {
                if (index === questionStep) {
                  // 2. objeyi (index 1) buluyoruz
                  return { ...obj, list: renderData }; // list'i renderData ile değiştiriyoruz
                }
                return obj; // Diğer objelere dokunmuyoruz
              });
            });
            questionGetRaundData();
          }
          // setCheckupPage("result");
          // setComplete(true)
          // axios
          //   .get(`https://api.medcheckup.az/api/questions/${token}/?lang=en`)
          //   .then((res) => {
          //     console.log(res);
          //     let body = {
          //       step: questionList[questionStep].step + 1,
          //       list: res.data.results,
          //     };
          //     setQuestionList([...questionList, body]);
          //     setQuestionStep(questionStep + 1);
          //   })
          //   .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  }, [
    questionGetRaundData,
    questionStep,
    renderData,
    setQuestionList,
    token,
    validateSelections,
  ]);

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      setCheckupPage("dynamic-symptom");
      // setStaticSymptomData(data);
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, setCheckupPage]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      handleSubmit();
    };

    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [handleSubmit, nextButtonRef]);
  return (
    <>
      <div className="static-dynamic-symptom question">
        <p className="alert">{t("general.select_row")}</p>
        <form className="symptom-form">
          {renderData.length !== 0 &&
            renderData.map((item) => (
              <div className="form-group" key={item.id}>
                <label className="symptom" htmlFor={item.id}>
                  {item.question}
                </label>
                {item.answers.map((subanswer) => (
                  <div className="inp-area" key={subanswer.id}>
                    <div className="inp-group">
                      <input
                        type="radio"
                        name={item.id}
                        className={
                          errors[item.id] ? "radio-inp error" : "radio-inp "
                        }
                        value={subanswer.answer}
                        onChange={(e) => handleChange(e, item.id, subanswer.id)}
                        checked={item.select === subanswer.answer}
                      />
                      <span
                        className={errors[item.id] ? "text  textError" : "text"}
                      >
                        {subanswer.answer}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </form>
      </div>
    </>
  );
};

export default Question;
