const questionsDb = [
  {
    id: 1,
    page: "personal-select",
    // question: "Who is checkup for?",
    question_az: "Yoxlama kimin üçündür?",
    question_en: "Who is checkup for?",
    // firstTitle: "Myself",
     firstTitle_az: "Özüm",
     firstTitle_en: "Myself",
    // secondTitle: "Someone else",
    secondTitle_az: "Başqası üçün",
    secondTitle_en: "Someone else",
  },
  {
    id: 2,
    page: "gender-select",
    // question: "What is your sex?",
    question_az: "Cinsiyyət?",
    question_en: "What is your sex?",
    // firstTitle: "Female",
    firstTitle_az: "Qadın",
    firstTitle_en: "Female",
    // secondTitle: "Male",
    secondTitle_az: "Kişi",
    secondTitle_en: "Male",
  },

  {
    id: 3,
    page: "specific-info",

    questions: [
      {
        id: 1,
        type: "age",
        max: "100",
        // question: "How old are you?",
        question_az: "Neçə yaşınız var?",
        question_en: "How old are you?",
      },
      {
        id: 2,
        type: "weight",
        max: "150",
        // question: "What is weight?",
        question_az: "Çəkiniz neçədir?",
        question_en: "What is weight?",
      },
      {
        id: 3,
        type: "height",
        max: "220",
        // question: "What is the height?",
        question_az: "Boyunuz neçədir?",
        question_en: "What is the height?",
      },
    ],
  },
  {
    id: 4,
    page: "select-symptom",
    // question: "Add your symptoms",
    question_az: "Simptomlarınızı əlavə edin",
    question_en: "Add your symptoms",
  },
  {
    id: 5,
    page: "general-preliminary-inspection",
    // question: "Please check all the statements below that apply to you",
    question_az: "Zəhmət olmasa, sizə aid olan aşağıdakı ifadələrin hamısını yoxlayın",
    question_en: "Please check all the statements below that apply to you",
  },
  {
    id: 6,
    page: "select-region",
    // question: "Select regions",
    question_az: "Regionları seçin",
    question_en: "Select regions",
  },
  {
    id: 7,
    page: "dynamic-symptom",
    question: "Do you have any of the following symtoms?",
    question_az: "Sizdə aşağıdakı simptomlardan hər hansı biri varmı?",
    question_en: "Do you have any of the following symtoms?",
  },
  {
    id: 8,
    page: "yes-no-question",
    question: "Do you have pain any part of your face?",
    question_az: "",
    question_en: "",
  },
  {
    id: 9,
    page: "pain-level",
    question: "How strong is the headache?",
    question_az: "",
    question_en: "",
  },
];
export default questionsDb;
