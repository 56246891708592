/* ---------------------------------- React --------------------------------- */
import { useCallback, useContext, useEffect, useState } from "react";

/* ---------------------------------- Icon ---------------------------------- */
import negative from "../../assets/img/Icons/negative.svg";
import positive from "../../assets/img/Icons/positive.svg";
import { MainContext } from "../../utils/MainContext";
import CheckupCaption from "./CheckupCaption";

const PainLevel = () => {
  // Global State
  const {
    painLevel,
    setPainlevel,
    setCheckupPage,
    prevButtonRef,
    nextButtonRef,
    setComplete,
    symptomList,
    setSymptomList,

    // Function
    nextPageDirectroy,
    prevPageDirectroy,
  } = useContext(MainContext);

  // Local State
  const [renderData, setRenderData] = useState(null);

  /* ----------------------------- Specific color ----------------------------- */
  const [degrees, setDegrees] = useState([
    { number: 1, color: "#ffc037", active: true, select: true },
    { number: 2, color: "#ffb43a", active: false, select: false },
    { number: 3, color: "#fea73d", active: false, select: false },
    { number: 4, color: "#fd9b3f", active: false, select: false },
    { number: 5, color: "#fd8f42", active: false, select: false },
    { number: 6, color: "#fc8244", active: false, select: false },
    { number: 7, color: "#fc7647", active: false, select: false },
    { number: 8, color: "#fc6a49", active: false, select: false },
    { number: 9, color: "#fa5d4c", active: false, select: false },
    { number: 10, color: "#fa514f", active: false, select: false },
  ]);

  /* ----------------------------- Pick Pain Level ---------------------------- */
  useEffect(() => {
    degrees.map((item) => {
      if (item.select === true) {
        return setPainlevel(item.number);
      } else {
        return true;
      }
    });
  }, [degrees, setPainlevel]);
  // console.log(painLevel);

  /* --------------------------------- Select --------------------------------- */
  const handleDegreeClick = (index) => {
    const updatedDegrees = degrees.map((degree, i) => ({
      ...degree,
      active: i <= index,
      select: i === index && true,
    }));
    setDegrees(updatedDegrees);
  };

  /* ------------------------- Decrement && Increment ------------------------- */
  const handleDecrement = () => {
    const selectedDegree = degrees.find((degree) => degree.select);

    if (selectedDegree && selectedDegree.number > 1) {
      const updatedDegrees = degrees.map((degree) => {
        if (degree.number === selectedDegree.number - 1) {
          return { ...degree, active: true, select: true };
        } else if (degree.number === selectedDegree.number) {
          return { ...degree, active: false, select: false };
        } else {
          return degree;
        }
      });

      setDegrees(updatedDegrees);
    }
  };

  const handleIncrement = () => {
    const selectedDegreeIndex = degrees.findIndex((degree) => degree.select);

    if (selectedDegreeIndex !== -1 && selectedDegreeIndex < 9) {
      const updatedDegrees = degrees.map((degree, index) => ({
        ...degree,
        active: index <= selectedDegreeIndex + 1,
        select: index === selectedDegreeIndex + 1,
      }));

      setDegrees(updatedDegrees);
    }
  };

  // CheckData
  useEffect(() => {
    setRenderData(null);
    handleDegreeClick(0);
    let specificObjData = symptomList.list[symptomList.currentInnerSymptom];
    if (specificObjData.widget_for_question === "pain_level") {
      setRenderData(specificObjData);
      if (specificObjData.select) {
        handleDegreeClick(specificObjData.select - 1);
      }
    }
  }, [
    symptomList.currentInnerSymptom,
    symptomList.currentPageIndex,
    symptomList.list,
  ]);
  // HandleClick Not add

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      prevPageDirectroy(symptomList.currentInnerSymptom - 1);
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, prevPageDirectroy, setCheckupPage, symptomList.currentInnerSymptom]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      // setCheckupPage("result");
      // setComplete(true);

      let generalDatas=symptomList.list
      generalDatas[symptomList.currentInnerSymptom] = {
        ...generalDatas[symptomList.currentInnerSymptom], // Mevcut objeyi koru
        select: painLevel , // Sadece 'inner' key'ini değiştir
      };
    

      let specificObjData = symptomList.list[symptomList.currentInnerSymptom];
      setSymptomList((prev) => ({
        ...prev,
        list: prev.list.map((item) =>
          item.id === specificObjData.id ? { ...item, select: painLevel } : item
        ),
      }));
  
      // Next Page
      nextPageDirectroy(symptomList.currentInnerSymptom + 1,generalDatas);
    };

    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [nextButtonRef, nextPageDirectroy, painLevel, setSymptomList, symptomList.currentInnerSymptom, symptomList.list]);

  return (
    <>
      {renderData && (
        <CheckupCaption title={renderData.question_for_inner_symptoms} />
      )}
      <div className="pain-level">
        <div className="row">
          {degrees.map((degree, index) => (
            <div
              key={index}
              className={`degree ${degree.select ? "active" : ""}`}
              onClick={() => handleDegreeClick(index)}
            >
              <div className="top">
                <span className="number">{degree.number}</span>
                <div className="angle">
                  <span className="triangle"></span>
                </div>
              </div>
              <div
                className="bottom"
                style={{ background: degree.active ? degree.color : "" }}
              ></div>
            </div>
          ))}
        </div>
        <div className="btn-group">
          <button className="decrement" onClick={handleDecrement}>
            <img src={negative} alt="-" />
          </button>
          <button className="increment" onClick={handleIncrement}>
            <img src={positive} alt="+" />
          </button>
        </div>
      </div>
    </>
  );
};

export default PainLevel;
