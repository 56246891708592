import logout from "../assets/img/Icons/logout.svg";
import account from "../assets/img/Icons/account.svg";
import accountActive from "../assets/img/Icons/account-active.svg";
import pass from "../assets/img/Icons/changepass.svg";
import passactive from "../assets/img/Icons/changepass-active.svg";
import { useContext } from "react";
import { MainContext } from "../utils/MainContext";
import historyactive from '../assets/img/Icons/history-active.svg'
import history from '../assets/img/Icons/history.svg'

const ProfileSideBar = () => {
  // Global State
  const { profileActivePage, setProfileActivePage } = useContext(MainContext);

  return (
    <aside className="profile-sidebar">
      {/* <div className="mobile-caption"><span className="icon"><img src={account} alt="account" /></span>Account</div> */}
      <ul className="profile-list">
        <li
          className={
            profileActivePage === "Account"
              ? "profile-item active"
              : "profile-item"
          }
          onClick={() =>
            profileActivePage !== "Account" && setProfileActivePage("Account")
          }
        >
          <span className="icon">
            <img
              src={profileActivePage === "Account" ? accountActive : account}
              alt="account"
            />
          </span>
          Account
        </li>
        <li
          className={
            profileActivePage === "Password change"
              ? "profile-item active"
              : "profile-item"
          }
          onClick={() =>
            profileActivePage !== "Password change" &&
            setProfileActivePage("Password change")
          }
        >
          <span className="icon">
            <img src={      profileActivePage === "Password change" ?passactive  : pass} alt="pass" />
          </span>
          Password change
        </li>
        <li
          className={
            profileActivePage === "History"
              ? "profile-item active"
              : "profile-item"
          }
          onClick={() =>
            profileActivePage !== "History" && setProfileActivePage("History")
          }
        >
          <span className="icon">
            <img src={  profileActivePage === "History" ? historyactive : history} alt="account" />
          </span>
          History
        </li>
        <li className="profile-item">
          <span className="icon">
            <img src={logout} alt="logout" />
          </span>
          Log out
        </li>
      </ul>
    </aside>
  );
};

export default ProfileSideBar;
