/* ---------------------------------- React --------------------------------- */
import { useContext, useEffect, useState } from "react";
import checkUpMap from "../../db/checkUpMap";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

const ProgressBar = () => {
  const [data, setData] = useState(null);
  const { checkupPage } = useContext(MainContext);

  // language
  const { i18n } = useTranslation();

  useEffect(() => {
    if (checkupPage) {
      let handleStep = checkUpMap.find(
        (item) => item.sturucture === checkupPage
      );
      setData(handleStep);
    }
  }, [checkupPage]);
  return (
    <>
      <div className="progress-bar ">
        <div className={`radialProgressBar progress-${data?.step} `}>
          <div className="info-area">
            <span className="text">{data?.[`text_${i18n.language}`]}</span>

            <span className="circle">{data?.step}/6</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
