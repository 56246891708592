import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import arrow from "../../assets/img/Icons/learn-more.svg";

const ResultSymptom = ({ data }) => {
  const [renderData, setRenderData] = useState([]);
  useEffect(() => {
    if (data) {
      let oldData = data.diagnosted_diseases;

      const sortedData = oldData.sort(
        (a, b) =>
          data._diseases_score_json[b.id] - data._diseases_score_json[a.id]
      );
      setRenderData(sortedData);
    }
  }, [data]);
  return (
    <div className="result-symptom">
      <div className="result-container">
        <h6 className="caption">The possibility of other diseases</h6>
        {renderData &&
          renderData.map((disease) => (
            <div className="illness" key={disease.id}>
              <div className="degree">
                <div className="status">
                  <span className={disease.importance_level}></span>
                </div>
                <p className="text">{disease.category[0].title}</p>
              </div>
              <h6 className="ilness-name">{disease.title}</h6>
              <div className="videoDoctorDirect">
                <Link to="https://videodoktor.az/" target="_blank">
                  
                  Learn more
                  <span className="icon">
                  
                    <img src={arrow} alt="icon" />
                  </span>
                </Link>
              </div>
            </div>
          ))}

        <p className="alert">
          List of possible conditions may not be complete, is provided solely
          for informational purposes, is not a qualified medical opinion and can
          not replace the medical diagnosis.
        </p>
      </div>
    </div>
  );
};
// hiqh
// middle
// low

export default ResultSymptom;
