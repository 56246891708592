import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./page/Home";
import NotFoundPage from "./page/NotFoundPage";
import CheckUpPage from "./page/CheckUpPage";
import { AppContext } from "./utils/MainContext";
import SignUp from "./page/SingUp";
import Login from "./page/Login";
import ForgetPassword from "./page/ForgetPassword";
import Profile from "./page/Profile";

const App = () => {
  return (
    <>
      <Header />
      <AppContext>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="check-up" element={<CheckUpPage />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="login" element={<Login />} />
          <Route path="profile" element={<Profile />} />
          <Route path="forgot-password" element={<ForgetPassword />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AppContext>
      <Footer />
    </>
  );
};

export default App;
