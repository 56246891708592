/* ---------------------------------- React --------------------------------- */
import React, { useContext, useEffect, useState } from "react";

/* ------------------------------ React Slider ------------------------------ */
import ReactSlider from "react-slider";

/* -------------------------------- Component ------------------------------- */
import CheckupCaption from "./CheckupCaption";

/* --------------------------------- Context -------------------------------- */
import { MainContext } from "../../utils/MainContext";

/* ---------------------------------- Icon ---------------------------------- */
import negative from "../../assets/img/Icons/negative.svg";
import positive from "../../assets/img/Icons/positive.svg";

const BodyInfo = ({ questionText, type, max, defaultValue }) => {
  /* --------------------------------- Context -------------------------------- */
  const { setBodyInfo } = useContext(MainContext);

  /* ---------------------------------- State --------------------------------- */
  const [value, setValue] = useState(0);

  /* ---------------------------- Set Default Varue --------------------------- */
  useEffect(() => {
    setValue(Number(defaultValue));
  }, [defaultValue]);

  /* -------------------------------- Set Value ------------------------------- */
  useEffect(() => {
    setBodyInfo((prevBodyInfo) => ({
      ...prevBodyInfo,
      [type]: value,
    }));
  }, [type, value, setBodyInfo]);

  /* ------------------------ Decrement  &&  Increment ------------------------ */
  const handleDecrement = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };
  const handleIncrement = () => {
    if (value < Number(max)) {
      setValue(value + 1);
    }
  };

  return (
    <div className="body-info">
      <CheckupCaption title={questionText} className={"static"} />
      <div className="row">
        <button className="decrement" onClick={handleDecrement}>
          <img src={negative} alt="-" />
        </button>
        <div className="slider">
          <ReactSlider
            ariaLabelledby="slider-label"
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            max={Number(max)}
            renderThumb={(props, state) => (
              <div {...props}>{state.valueNow}</div>
            )}
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </div>
        <button className="increment" onClick={handleIncrement}>
          <img src={positive} alt="+" />
        </button>
      </div>
    </div>
  );
};

export default BodyInfo;
