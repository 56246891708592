import React from "react";
import Button from "../../component/Button";
import { useTranslation } from "react-i18next";

const Hero = () => {
  // Language
  const {t}=useTranslation()
  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="info">
            <h1 className="title">{t("hero.caption")}</h1>
            <p className="detail">
            {t("hero.detail")}
            </p>
            <Button text={t("hero.start_checkup")} link={"/check-up"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
