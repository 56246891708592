import Hero from "./home-section/Hero";
import MedCheckupInfo from "./home-section/MedCheckupInfo";
import Reference from "./home-section/Reference";
import Impressions from "./home-section/Impressions";
import WebsiteDescription from "./home-section/WebsiteDescription";

const Home = () => {
  return (
    <main>
      <Hero />
      <MedCheckupInfo />
      <Reference />
      <Impressions />
      <WebsiteDescription/>
    </main>
  );
};

export default Home;
