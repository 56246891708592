/* ---------------------------------- React --------------------------------- */
import { useState } from "react";

/* ---------------------------- React Router Dom ---------------------------- */
import { Link, useLocation } from "react-router-dom";

/* ---------------------------------- Icons --------------------------------- */
import instagram from "../assets/img/Icons/instagram.svg";
import linkedin from "../assets/img/Icons/linkedin.svg";
import facebook from "../assets/img/Icons/facebook.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const location = useLocation();
  // const [langActive, setLangActive] = useState(false);

  // const { i18n } = useTranslation();

  // // Change Language
  // const selectLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  return (
    <footer
      className="footer"
      style={
        location.pathname !== "/" &&
        location.pathname !== "/check-up" &&
        location.pathname !== "/login" &&
        location.pathname !== "/sign-up" &&
        location.pathname !== "/forgot-password" &&
        location.pathname !== "/profile"
          ? { display: "none" }
          : {}
      }
    >
      <div className="pattern"></div>
      <div className="container">
        <div className="row">
          <ul className="social-list">
            <li className="social-item">
              <Link>
                <div className="image">
                  <img src={instagram} alt="instagram" />
                </div>
              </Link>
            </li>
            <li className="social-item">
              <Link>
                <div className="image">
                  <img src={linkedin} alt="linkedin" />
                </div>
              </Link>
            </li>
            <li className="social-item">
              <Link>
                <div className="image">
                  <img src={facebook} alt="facebook" />
                </div>
              </Link>
            </li>
          </ul>
          <div className="last-text">
            <p>© 2023 MedCheckup</p>
          </div>
          {/* <div
            className="lang-area"
            onClick={() => {
              setLangActive(!langActive);
            }}
          >
            <span className="lang-text">
              {i18n.language === "az" ? "Azerbaijan" : "English"}{" "}
            </span>
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 1.25L6.29289 6.04289C6.68342 6.43342 7.31658 6.43342 7.70711 6.04289L12.5 1.25"
                stroke="#334155"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
            <ul className={langActive ? "lang-list active" : "lang-list"}>
              <li
                className="lang-item"
                onClick={() => {
                  selectLanguage("az");
                  setLangActive(false);
                }}
              >
                Azerbaijan
              </li>
              <li
                className="lang-item"
                onClick={() => {
                  selectLanguage("en");
                  setLangActive(false);
                }}
              >
                Ingilis
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      {/* <div
        className={langActive ? "overlay active" : "overlay"}
        onClick={() => {
          if (langActive) {
            setLangActive(false);
          }
        }}
      ></div> */}
    </footer>
  );
};

export default Footer;
