

const Account = () => {
  return (
    <section className="account-password">
      <h1 className="user-name">Kamil Kamilov</h1>
      <form className="personality-information">
        <div className="form-group">
          <label className="label">Full name</label>
          <input type="text" />
        </div>
        <div className="form-group">
          <label className="label">Birthday</label>
          <input type="date" />
        </div>
        <div className="form-group">
          <label className="label">Mail</label>
          <input type="text" />
        </div>
        <div className="button-area">
          <button>Save</button>
        </div>
      </form>
    </section>
  );
};

export default Account;
