const checkUpMap = [
  {
    id: 1,
    step: 1,
    stepName: "Introduction",
    text_az: "Giriş",
    text_en: "Introduction",
    sturucture: "general-info",
  },
  {
    id: 2,
    step: 2,
    stepName: "Patient",
    text_az: "Xəstə",
    text_en: "Patient",
    sturucture: "personal-select",
  },
  {
    id: 3,
    step: 2,
    stepName: "Patient",
    text_az: "Xəstə",
    text_en: "Patient",
    sturucture: "gender-select",
  },
  {
    id: 4,
    step: 2,
    stepName: "Patient",
    text_az: "Xəstə",
    text_en: "Patient",
    sturucture: "specific-info",
  },
  {
    id: 5,
    step: 3,
    stepName: "Regions",
    text_az: "Regionlar",
    text_en: "Regions",
    sturucture: "select-region",
  },
  {
    id: 6,
    step: 3,
    stepName: "Patient",
    text_az: "Xəstə",
    text_en: "Patient",
    sturucture: "general-preliminary-inspection",
  },

  {
    id: 7,
    step: 4,
    stepName: "Symptoms",
    text_az: "Simptomlar",
    text_en: "Symptoms",
    sturucture: "select-symptom",
  },
  {
    id: 8,
    step: 5,
    stepName: "Question",
    text_az: "Suallar",
    text_en: "Question",
    sturucture: "question",
  },
  {
    id: 9,
    step: 5,
    stepName: "Interview",
    text_az: "Müsahibə",
    text_en: "Interview",
    sturucture: "dynamic-symptom",
  },
  {
    id: 10,
    step: 5,
    stepName: "Interview",
    text_az: "Müsahibə",
    text_en: "Interview",
    sturucture: "yes-no-question",
  },
  {
    id: 11,
    step: 5,
    stepName: "Interview",
    text_az: "Müsahibə",
    text_en: "Interview",
    sturucture: "pain-level",
  },
  {
    id: 12,
    step: 5,
    stepName: "Interview",
    text_az: "Müsahibə",
    text_en: "Interview",
    sturucture: "temprature",
  },
  {
    id: 13,
    step: 6,
    stepName: "Results",
    text_az: "Nəticələr",
    text_en: "Results",
    sturucture: "result",
  },
];
export default checkUpMap;
