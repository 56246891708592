import { useContext } from "react";
import play from "../../assets/img/Icons/play.svg";
import HomeCaption from "./HomeCaption";
import { MainContext } from "../../utils/MainContext";
import WebsiteVideo from "./WebsiteVideo";
import { useTranslation } from "react-i18next";

const WebsiteDescription = () => {
  const { videoVisible, setVideoVisible } = useContext(MainContext);

  // language
  const { t } = useTranslation();
  return (
    <section className="website-description">
      <div className="container">
        <div className="row">
          <HomeCaption
            title={t("website_description.caption")}
            detail={
              "Problems trying to resolve the conflict between the two major realms of Classical physics: Newtonian mechanics "
            }
          />
          <div className="video-area" onClick={() => setVideoVisible(true)}>
            <div className="bg-img">
              <img src="" alt="" />
            </div>
            <div className="play-icon">
              <img src={play} alt="play-icon" />
            </div>
          </div>
        </div>
      </div>
      <div className={videoVisible ? "video-modal visible" : "video-modal"}>
        <WebsiteVideo />
      </div>
    </section>
  );
};

export default WebsiteDescription;
