/* ---------------------------------- React --------------------------------- */
import { useContext } from "react";

/* --------------------------------- Context -------------------------------- */
import { MainContext } from "../../utils/MainContext";

/* -------------------------------- Component ------------------------------- */
import BodyWomanFrontDesktop from "../checkup-body/BodyWomanFrontDesktop";
import BodyWomanBackDesktop from "../checkup-body/BodyWomanBackDesktop";
import BodyManFrontDesktop from "../checkup-body/BodyManFrontDesktop";
import BodyManBackDesktop from "../checkup-body/BodyManBackDesktop";

const BodyDesktop = () => {
  const { gender } = useContext(MainContext);
  return (
    <>
      {gender === "female" ? (
        <>
          <BodyWomanFrontDesktop />
          <BodyWomanBackDesktop />
        </>
      ) : (
        <>
          <BodyManFrontDesktop />
          <BodyManBackDesktop />
        </>
      )}
    </>
  );
};

export default BodyDesktop;
