/* ---------------------------------- React --------------------------------- */
import { useContext, useEffect, useState } from "react";

/* --------------------------------- Context -------------------------------- */
import { MainContext } from "../../utils/MainContext";

/* ------------------------------- Components ------------------------------- */
import CheckupCaption from "../../component/checkup/CheckupCaption";
import CheckUpPagination from "../../component/checkup/CheckUpPagination";
import GeneralInfo from "../../component/checkup/GeneralInfo";
import PersonalGenderSelect from "../../component/checkup/PersonalGenderSelect";
import SpecificInfo from "../../component/checkup/SpecificInfo";
import GeneralPreliminaryInspection from "../../component/checkup/GeneralPreliminaryInspection";
import SelectSymptom from "../../component/checkup/SelectSymptom";
import SelectRegion from "../../component/checkup/SelectRegion";
import DynamicSymptom from "../../component/checkup/DynamicSymptom";
import YesNoQuestion from "../../component/checkup/YesNoQuestion";
import PainLevel from "../../component/checkup/PainLevel";
import questionsDb from "../../db/questionsDb";
import { useTranslation } from "react-i18next";
import Question from "../../component/checkup/Question";
import Temperature from "../../component/checkup/Temperature";

const CheckupArea = () => {
  const { checkupPage } = useContext(MainContext);
  const [question, setQuestion] = useState("");
  // Language
  const{i18n}=useTranslation()

  useEffect(() => {
    const foundQuestion = questionsDb.find((item) => {
      if (checkupPage === "general-info" || checkupPage === "specific-info") {
        return false;
      } else {
        return item.page === checkupPage;
      }
    });

    if (foundQuestion) {
      setQuestion(foundQuestion[`question_${i18n.language}`]);
    }
  }, [checkupPage, i18n.language]);
  return (
    <div className="checkup-area">
      <div
        className={
          checkupPage === "general-info" || checkupPage === "specific-info"
            ? "checkup-container deActiveQuestion"
            : "checkup-container"
        }
      >
        {checkupPage !== "general-info" && checkupPage !== "specific-info" && checkupPage !== "dynamic-symptom" &&   checkupPage !== "temprature"   && checkupPage !== "question" &&  (
          <CheckupCaption title={question} />
        )} 
        {/* burada title fikir ver pain lever dinamik suallarda bu olmayacaq,dinamikin bezilerinde */}
        {/* Introduction/Step 1 */}
        {checkupPage === "general-info" ? (
          <GeneralInfo />
        ) : //  Patient/Step 2
        checkupPage === "personal-select" ? (
          <PersonalGenderSelect />
        ) : checkupPage === "gender-select" ? (
          <PersonalGenderSelect />
        ) : checkupPage === "specific-info" ? (
          <SpecificInfo />
        ) : checkupPage === "general-preliminary-inspection" ? (
          <GeneralPreliminaryInspection />
        ) : //  Regions/Step 3
        checkupPage === "select-region" ? (
          <SelectRegion />
        ) : //  Symptoms/Step 4
        checkupPage === "select-symptom" ? (
          <SelectSymptom />
        ) :// Interview / Step 5  loop
         checkupPage === "question" ? (
          <Question />
        ) : 
         checkupPage === "temprature" ? (
          <Temperature />
        ) : 
        checkupPage === "dynamic-symptom" ? (
          <DynamicSymptom />
        ) : checkupPage === "yes-no-question" ? (
          <YesNoQuestion />
        ) : checkupPage === "pain-level" ? (
          <PainLevel />
        ) : null}
        <CheckUpPagination />
      </div>
    </div>
  );
};

export default CheckupArea;
