import React from "react";
import { Link } from "react-router-dom";

const HomeCaption = ({ title, detail, longText ,link=""}) => {
  return (
    <div className="home-caption">
      <span className="line"></span>
      <h2 className="title">{title}</h2>
      {detail && <p className="detail" >{detail}</p>}
      {longText && (
        <Link to={link} target="_blank" className="button">Learn More</Link>
      )}
    </div>
  );
};

export default HomeCaption;
