import { useContext } from "react";
import close from "../../assets/img/Icons/close-circle.svg";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

const SearchKeywordsArea = () => {
  const { symptomList, removeSymptomInList } = useContext(MainContext);

  const{t}=useTranslation()
  return (
    symptomList && (
      <div className="keywords-area">
        {symptomList.list.length === 0 && (
          <p className="alert">{t("select_symptom.add_symptom")}</p>
        )}
        {symptomList.list.map((key) => (
          <div className="item" key={key.id}>
            <span className="text">{key.title} </span>
            <div className="close" onClick={() => removeSymptomInList(key.id)}>
              <img src={close} alt="close-icon" />
            </div>
          </div>
        ))}
      </div>
    )
  );
};

export default SearchKeywordsArea;
