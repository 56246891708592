/* ---------------------------------- React --------------------------------- */
import { Link } from "react-router-dom";

/* ---------------------------------- Icon ---------------------------------- */
import down from "../../assets/img/Icons/down.svg";
import { useContext, useRef, useState } from "react";
import { MainContext } from "../../utils/MainContext";

const AnswerInfo = ({ data }) => {
  const { bodyInfo, gender } = useContext(MainContext);

  const [visible, setVisible] = useState(false);

  const answer = useRef(null);
  return (
    <div className="answer-info">
      <div className="top">
        <div className="result-container">
          <div className="row">
            <h6 className="caption">Your answers</h6>
            <div className="show-button" onClick={() => setVisible(!visible)}>
              {visible ? "Hide" : "Show"}{" "}
              <img
                src={down}
                alt="down-icon"
                className={visible ? "icon active" : "icon"}
              />
            </div>
          </div>
          <div
            className={
              visible
                ? "symptom-answer scroll  active"
                : "symptom-answer scroll"
            }
            style={{
              height: visible ? `500px` : "0px",
              // height: visible ? `${answer.current.clientHeight}px` : "0px",
            }}
          >
            {data && (
              <div className="answer" ref={answer}>
                <div className="body-info">
                  <div className="card">
                    <h6 className="title">Sex</h6>
                    <p className="details">{gender}</p>
                  </div>
                  <div className="card">
                    <h6 className="title">Age</h6>
                    <p className="details">{bodyInfo.age} years</p>
                  </div>
                  <div className="card">
                    <h6 className="title">Weight</h6>
                    <p className="details">{bodyInfo.weight} Kq</p>
                  </div>
                  <div className="card">
                    <h6 className="title">Height</h6>
                    <p className="details">{bodyInfo.height} sm</p>
                  </div>
                </div>
                <div className="region-info">
                  <h6 className="title">Regions</h6>
                  <p className="text">
                    {data.region.map((item) => item.title).join(",")}
                  </p>
                </div>
                <div className="choose-symptom">
                  <h6 className="title">Reported symptoms</h6>
                  <p className="text">
                    {data.symptoms.map((item) => item.title).join(",")}
                  </p>
                </div>
                <div className="choose-symptom">
                  <h6 className="title">Chronic Diseases</h6>
                  <p className="text">
                    {data.chronic_diseases.map((item) => item.title).join(",")}
                  </p>
                </div>
                <div className="other-symptom">
                  <h6 className="title">Other Detail</h6>
            
                  <ul className="symptom-list">
                    <p className="caption">Question:</p>
                    {data.answers.map((questions) => (
                      <li className="symptom-item " key={questions.id}>
                        <p className="question">{questions.question}</p>
                        <p className="question-answer">{questions.answer}</p>
                      </li>
                    ))}
                  </ul>
                  {/* <ul className="symptom-list">
                  <p className="caption">Absent:</p>
                  <li className="symptom-item reject">
                    Headaches for 3 months or more
                  </li>
                  <li className="symptom-item reject">
                    Headaches for 3 months or more
                  </li>
                  <li className="symptom-item reject">
                    Headaches for 3 months or more
                  </li>
                </ul>
                <ul className="symptom-list">
                  <p className="caption">Skipped:</p>
                  <li className="symptom-item skip">
                    Headaches for 3 months or more
                  </li>
                </ul> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="result-container">
          <div className="row">
            <Link className="btn">Download PDF report</Link>
            <button className="btn">Start new checkup </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerInfo;
