/* ---------------------------- React Router Dom ---------------------------- */
import { useLocation, useNavigate } from "react-router-dom";

/* ---------------------------------- Icons --------------------------------- */
import next from "../assets/img/Icons/next-btn.svg";
import { useContext, useEffect, useRef } from "react";
import { MainContext } from "../utils/MainContext";

const ButtonNext = ({ text, link, image, className }) => {
  /* --------------------------------- Context -------------------------------- */
  const { setNextButtonRef } = useContext(MainContext);

  /* ---------------------------- React Router Dom ---------------------------- */
  const location = useLocation();
  const navigate = useNavigate();

  const btnRef = useRef();

  useEffect(() => {
    if (btnRef) {
      setNextButtonRef(btnRef);
    }
  }, [setNextButtonRef]);

  return (
    <button
    type="submit"
      ref={btnRef}
      className={`btn ${className}`}
      onClick={() => {
        if (location.pathname === "check-up") {
          return true;
        } else {
          navigate(link);
        }
      }}
    >
      {text}
      {image && <img src={next} alt="next" className="next" />}
    </button>
  );
};

export default ButtonNext;
